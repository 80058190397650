/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow-sm"
      style={{ minHeight: "100px" }}
    >
      <div className="container">
        <Link to="/" className="navbar-brand" title="ALGERMED" rel="home">
          <img
            src="/assets/images/logo.png"
            alt="Home"
            style={{ height: 55 }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* <button className="btn my-2 my-sm-0 ml-auto" type="button">
            <Link to="/appointment">Appointment</Link>
          </button> */}
          <ul
            id="menu-main-primary-menu"
            class="nav navbar-nav sm ml-auto"
            data-smartmenus-id="1606817148123431"
          >
            <li
              id="menu-item-2914"
              class="custom-my-button-amir-menu menu-item menu-item-type-custom menu-item-object-custom menu-item-2914"
            >
              <button
                type="button"
                className="btn btn-lg"
                style={{ backgroundColor: "#04cdf8" }}
              >
                <Link
                  style={{
                    color: "#fff",
                    fontSize: "1.3rem",
                    fontWeight: "bolder",
                  }}
                  to="/appointment"
                >
                  Rendez-vous
                </Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
