import React from "react";
import { Banner } from "./banner";
import { Header } from "./header";
import { HomeText } from "./homeText";
import { Contact } from "./contact";
import { Video } from "./video";
import { Footer } from "./footer";

export const Home = () => {
  return (
    <div>
      <Header />
      <Banner />
      <HomeText />
      <Video />
      <Contact />
      <Footer />
    </div>
  );
};
