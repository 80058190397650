import React from "react";
import { InlineWidget } from "react-calendly";
import { Header } from "./header";

export const Calendly = () => {
  return (
    <>
      <Header />
      <div className="row">
        <div className="col-12">
          <img
            src="/assets/images/A (4).jpg"
            className="d-block w-100"
            style={{ height: 600, objectFit: "cover", opacity: ".8" }}
            alt="first"
          />
          <div
            className="overlay"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 2,
            }}
          ></div>
        </div>

        <div className="col-12">
          <InlineWidget url="https://calendly.com/algermed" />
        </div>
      </div>
    </>
  );
};
