import React, { useEffect } from "react";
import WOW from "wowjs";

export const HomeText = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <div className="container">
        <div className="wpb_wrapper">
          <h1
            className="wow slideInLeft"
            style={{ textAlign: "center", marginTop: "7rem" }}
          >
            Bienvenue au cabinet pédiatrie du Dr. Benabbas
          </h1>
          <h4
            className="wow slideInRight"
            style={{ textAlign: "center" }}
            data-wow-delay=".5s"
          >
            Nous accueillons les enfants et adolescents de 0 à 16 ans
          </h4>
          <p>&nbsp;</p>
        </div>
        <div
          id="consultations"
          className="vc_row wpb_row vc_row-fluid andaman_full_row_vc"
          style={{
            position: "relative",
            left: "-89.5px",
            boxSizing: "border-box",
            width: 1349,
            paddingLeft: "89.5px",
            paddingRight: "89.5px",
          }}
        >
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="promo-block center ">
                          <h1
                            className="wow bounceInDown title-p-line"
                            data-wow-delay=".5s"
                            style={{ marginTop: "5rem" }}
                          >
                            CONTRÔLE DU DÉVELOPPEMENT
                            <span className="promo-line" />
                          </h1>
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <div className="font12">
                              <h4
                                className="wow bounceInUp"
                                data-wow-delay=".5s"
                              >
                                Lors de cet examen, nous sommes à l’écoute de
                                vos inquiétudes et prenons le temps de discuter
                                avec vous des questions générales concernant
                                votre enfant. Nous prodiguons des conseils en
                                termes de médecine de prévention et suivons la
                                croissance de votre enfant tout au long de son
                                évolution.
                              </h4>
                              <h4
                                className="wow bounceInUp"
                                data-wow-delay=".5s"
                              >
                                Les âges habituels des contrôles de
                                développement et suivi de la croissance sont: 1,
                                2, 4, 6, 9, 12, 15, 18 mois. 2, 3, 4 ans. Par la
                                suite, chaque 1 à 2 ans
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1591715711619">
                      <div className="wpb_wrapper">
                        <div className="promo-block center ">
                          <h1
                            className="title-p-line wow bounceInLeft"
                            data-wow-delay=".5s"
                            style={{ marginTop: "9rem" }}
                          >
                            CONTRÔLE PRÉSCOLAIRE/SCOLAIRE
                            <span className="promo-line" />
                          </h1>
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <h4
                              className=" wow bounceInRight"
                              data-wow-delay=".5s"
                            >
                              Le contrôle préscolaire s’effectue à l’âge de 4
                              ans le contrôle scolaire à l’âge de 6 ans et vers
                              11 ans.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1591715765898">
                      <div className="wpb_wrapper">
                        <div className="promo-block center ">
                          <h1
                            className="title-p-line wow slideInDown"
                            data-wow-delay=".5s"
                            style={{ marginTop: "9rem" }}
                          >
                            CONSULTATION POUR ADOLESCENTS
                            <span className="promo-line" />
                          </h1>
                        </div>
                        <div className="wpb_text_column wpb_content_element">
                          <div className="wpb_wrapper">
                            <h4
                              className="  wow slideInUp"
                              data-wow-delay=".5s"
                            >
                              La consultation est centrée sur les problématiques
                              spécifiques aux adolescent(e)s, notamment :
                            </h4>
                            <ul style={{ marginLeft: "-1.3rem" }}>
                              <li>
                                <h4
                                  className=" wow slideInUp"
                                  data-wow-delay=".5s"
                                >
                                  Les questions de croissance, de puberté et
                                  endocriniens (avec pour les jeunes filles des
                                  conseils également en matière de gynécologie).
                                </h4>
                              </li>
                              <li>
                                <h4
                                  className=" wow slideInUp"
                                  data-wow-delay=".5s"
                                >
                                  Le suivi somatique des troubles du
                                  comportement alimentaire (anorexie, boulimie,
                                </h4>
                                <h4
                                  className=" wow slideInUp"
                                  data-wow-delay=".5s"
                                >
                                  troubles alimentaires non spécifiques, etc…)
                                </h4>
                              </li>
                              <li>
                                <h4
                                  className=" wow slideInUp"
                                  data-wow-delay=".5s"
                                >
                                  Les troubles fonctionnels
                                </h4>
                              </li>
                              <li>
                                <h4
                                  className=" wow slideInUp"
                                  data-wow-delay=".5s"
                                >
                                  Le suivi général de maladies chroniques ou
                                  encore les situations scolaires,
                                </h4>
                                <h4
                                  className=" wow slideInUp"
                                  data-wow-delay=".5s"
                                >
                                  professionnelles, familiales ou psychosociales
                                  complexes avec tout le travail en réseau que
                                  ces situations demandent.
                                </h4>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1591715829812">
                      <div className="wpb_wrapper">
                        <div className="promo-block center ">
                          <h1
                            className="title-p-line  wow fadeInLeft"
                            data-wow-delay=".5s"
                            style={{ marginTop: "9rem" }}
                          >
                            CONSULTATION EN URGENCE
                            <span className="promo-line" />
                          </h1>
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <h4
                              className=" wow fadeInRight"
                              data-wow-delay=".5s"
                            >
                              Par exemple en cas de fièvre, difficultés
                              alimentaires, rhume et toux, vomissements,
                              agitation, pleurs ou pour toute autre inquiétude
                            </h4>
                            <h4
                              className=" wow fadeInRight"
                              data-wow-delay=".5s"
                            >
                              En cas d’urgence, Il est préférable de nous
                              contacter par téléphone avant de venir, nous vous
                              recevons rapidement selon les disponibilités de
                              notre agenda
                            </h4>
                            <h4
                              className=" wow fadeInRight"
                              data-wow-delay=".5s"
                            >
                              Nous recevons également en urgences les enfants en
                              vacances ou de passage dans la région
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1591720025725">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1591715836914">
                      <div className="wpb_wrapper">
                        <div className="promo-block center ">
                          <h1
                            className="title-p-line wow fadeInDown"
                            data-wow-delay=".5s"
                            style={{ marginTop: "9rem" }}
                          >
                            FORMATIONS CONTINUES
                            <span className="promo-line" />
                          </h1>
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <h4 className=" wow fadeInUp" data-wow-delay=".5s">
                              Afin de soigner au mieux vos enfants, nous suivons
                              tout au long de l’année des formations
                              <br />
                              continues.
                            </h4>
                            <h4 className=" wow fadeInUp" data-wow-delay=".5s">
                              C’est pourquoi nous sommes parfois absents afin de
                              nous rendre aux différents cours et congrès de
                              notre région mais aussi en Suisse et en France.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
