import React from "react";

export const Footer = () => {
  return (
    <footer>
      <div className="footer black">
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="copyright-info m-auto" style={{ fontSize: 22 }}>
                  <p className="text--center">
                    © 2020 ALGERMED C.R.O 8241 / TZO, All Right Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
