import React, { useEffect } from "react";
import WOW from "wowjs";

export const Contact = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div
      style={{
        paddingTop: "10px",
        paddingBottom: "25px",
        backgroundColor: "#f9fafc",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="promo-block center ">
              <h1 className="title-p-line">
                NOUS CONTACTER
                <span className="promo-line" />
              </h1>
            </div>
          </div>
          <div className="col-12">
            <div className="promo-block  ">
              <h1 className="title-p-line">
                ALGERMED
                <span className="promo-line" />
              </h1>
            </div>
          </div>
          <div className="col">
            {/*  */}
            <div className="wpb_text_column wpb_content_element wow slideInLeft">
              <div className="wpb_wrapper">
                <h3>Cabinet de Pédiatrie à Boumerdès</h3>
                <h3>Dr. Nawal Benabbas</h3>
                <p style={{ color: "#777", fontSize: "20px" }}>
                  <strong>Tel.</strong>&nbsp; &nbsp;024 797 554
                  <br />
                  <strong>Mob.</strong> 055 5199 879
                </p>
                <p style={{ color: "#777", fontSize: "20px" }}>
                  info@algermed.com
                  <br />
                  www.algermed.com
                </p>
                <p style={{ color: "#777", fontSize: "20px" }}>
                  <strong>Horaires:</strong>
                  <br />
                  Samedi – Jeudi 09:00 – 17:00 heures
                  <br />
                  En cas d’urgence 24 heures
                </p>
                <p style={{ color: "#777", fontSize: "20px" }}>
                  <strong>Adresse:</strong>
                  <br />
                  Cité 1406 logement bâtiment 25
                  <br />
                  35000 Boumerdès
                  <br />
                  Algérie
                </p>
                <p
                  style={{
                    marginBottom: "8rem",
                  }}
                >
                  <strong>
                    <a
                      style={{
                        color: "#04cdf8",
                        fontSize: "22px",
                        marginBottom: "8rem",
                      }}
                      href="https://www.google.com/maps/place/36%C2%B045'24.2%22N+3%C2%B028'30.1%22E/@36.756726,3.4728493,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d36.756726!4d3.475038"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GPS LOCATION
                    </a>
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col wow slideInRight">
            <div className="wpb_wrapper">
              <div
                role="form"
                className="wpcf7"
                id="wpcf7-f335-p2909-o1"
                lang="en-US"
                dir="ltr"
              >
                <div className="screen-reader-response">
                  <p role="status" />
                  <ul />
                </div>
                <form
                  action="/#wpcf7-f335-p2909-o1"
                  method="post"
                  className="wpcf7-form init"
                  noValidate="novalidate"
                  data-status="init"
                >
                  <div className="form-group">
                    <label className="sr-only">Votre nom</label>
                    <span className="wpcf7-form-control-wrap your-name">
                      <input
                        type="text"
                        name="your-name"
                        placeholder="Votre nom"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                      />
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Votre adresse Email</label>
                    <span className="wpcf7-form-control-wrap your-email">
                      <input
                        type="email"
                        name="your-email"
                        placeholder="Votre adresse Email"
                        size={40}
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                      />
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Votre numéro de téléphone</label>
                    <span className="wpcf7-form-control-wrap your-phone">
                      <input
                        type="text"
                        name="your-phone"
                        placeholder="Votre numéro de téléphone"
                        size={40}
                        className="wpcf7-form-control wpcf7-text form-control"
                      />
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Votre message</label>
                    <span className="wpcf7-form-control-wrap your-message">
                      <textarea
                        name="your-message"
                        cols={40}
                        rows={4}
                        placeholder="Votre message"
                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                      />
                    </span>
                  </div>
                  <p>
                    <input
                      type="submit"
                      value="Envoyer"
                      className="wpcf7-form-control wpcf7-submit"
                    />
                    <span className="ajax-loader" />
                  </p>
                  <div className="wpcf7-response-output" />
                </form>
              </div>
              <div className="wpb_text_column wpb_content_element  vc_custom_1591792238824">
                <div className="wpb_wrapper">
                  <p style={{ textAlign: "center", marginTop: "1.5rem" }}>
                    Paiement par carte de crédit est possible sur notre compte
                    paypal:
                    <br />
                    <a
                      className="Xx"
                      dir="ltr"
                      tabIndex={-1}
                      href="mailto:info@algermed.com"
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                      data-display="info@algermed.com"
                      data-sanitized="mailto:info@algermed.com"
                    >
                      info@algermed.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1591716546641">
                <figure className="wpb_wrapper vc_figure text-center">
                  <div className="vc_single_image-wrapper vc_box_border_grey">
                    <img
                      width={500}
                      height={90}
                      src="/assets/images/icons.png"
                      className="vc_single_image-img attachment-medium"
                      alt="contact"
                      loading="lazy"
                    />
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
