import React, { useEffect } from "react";
import WOW from "wowjs";

export const Video = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div className="container mb-5">
      <video
        controls
        autoplay
        loop
        style={{ width: "100%", marginTop: "7rem" }}
        className=" wow pulse"
        data-wow-delay=".7s"
      >
        <source src="/assets/images/Video_Baby_Check.mp4" type="video/mp4" />
        <source src="/assets/images/Video_Baby_Check.mp4" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
