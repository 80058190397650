import { React } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./components/home";
import { Calendly } from "./components/calendly";
import { ScrollToTop } from "./components/scrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/appointment">
            <Calendly />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
