import React, { Component } from "react";
import WOW from "wowjs";

export class Banner extends Component {
  componentDidMount() {
    const wow = new WOW.WOW();

    wow.init();
  }

  render() {
    return (
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-ride="carousel"
        data-interval="2000"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleCaptions"
            data-slide-to={0}
            className="active"
          />
          <li data-target="#carouselExampleCaptions" data-slide-to={1} />
          <li data-target="#carouselExampleCaptions" data-slide-to={2} />
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div
              className="overlay"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            ></div>

            <img
              src="/assets/images/slider-1-2.jpg"
              className="d-block w-100"
              style={{ height: "700px", objectFit: "cover", opacity: ".8" }}
              alt="first"
            />

            <div
              className="carousel-caption d-none d-md-block"
              style={{ marginBottom: "10%" }}
            >
              <h2
                className="wow fadeInUp animated"
                data-wow-delay=".5s"
                style={{ fontWeight: "3rem" }}
              >
                ALGERMED vous souhaite la bienvenue sur son site internet
              </h2>
              <h1
                className="wow fadeInDown animated"
                style={{ fontWeight: "4rem" }}
              >
                BIENVENUE
              </h1>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="overlay"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            ></div>
            <img
              src="/assets/images/A-5-scaled.jpg"
              className="d-block w-100"
              style={{ height: "700px", objectFit: "cover", opacity: ".8" }}
              alt="second"
            />

            <div
              className="carousel-caption d-none d-md-block"
              style={{ marginBottom: "10%" }}
            >
              <h2
                data-wow-delay=".5s"
                class="wow fadeInUp animated"
                style={{ fontWeight: "3rem" }}
              >
                En raison de l'évolution actuelle autour du coronavirus, il est
                possible de nous contacter 7 jours / 24 heures
              </h2>
              <h1
                class="wow fadeInDown animated"
                data-wow-delay=".1s"
                style={{ fontWeight: "4rem" }}
              >
                INFORMATION COVID-19
              </h1>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="overlay"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            ></div>
            <img
              src="/assets/images/NOUVELLE_OUVERTURE_v2.jpg"
              className="d-block w-100"
              style={{ height: "700px", objectFit: "cover", opacity: ".8" }}
              alt="third"
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="prev"
          style={{ zIndex: 2 }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleCaptions"
          role="button"
          data-slide="next"
          style={{ zIndex: 2 }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}
